<template>
  <div class="admin-feed-block">
    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <AdminStatisticsFilter :statistics="statistics" :title="$locale['verifications']" />
      <div class="statistics-block-content">
        <AdminProfileVerifyCard v-for="(user, index) in verifications" :key="index" :user="user" />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    LastPeriodFilter: () => import("../widgets/LastPeriodFilter.vue"),
    AdminProfileVerifyCard: () => import("./AdminProfileVerifyCard.vue"),
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
  },
  data: function() {
    return {
      loading: true,
      startPeriod: "last-6-months",
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function({ page }) {
      const { period, startDate, endDate } = this.$route.query;
      let query = `?period=${period || this.startPeriod}&page=${page < 1 ? 1 : page}`;
      query += `&startDate=${startDate}&endDate=${endDate}`;
      return query;
    },
    getStatistics: async function({ page }) {
      this.isLoading(true);
      try {
        const query = this.getQuery({ page });
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/documents-home${query}`);
        this.$store.commit("setProfileVerifications", response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
  },
  mounted: async function() {
    this.isLoading(true);
    await this.sleep(1000);
    this.getStatistics({ page: 1 });
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    verifications: function() {
      return this.$store.getters.verifications.profiles.items;
    },
    statistics: function() {
      return this.$store.getters.verifications.profiles.statistics;
    },
  },
  watch: {
    "$route.query": function() {
      this.getStatistics({ page: 1 });
    },
  },
};
</script>
